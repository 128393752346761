<template>
    <zw-sidebar @shown="shown" :title="$t('stock.title.supply')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row>
                    <b-col sm="4">
                        <search-field v-model="form.supplier_id"
                                      name="supplier"
                                      :label-prefix="labelPrefix"
                                      :url="searchUrl"
                                      validate="required"
                                      :additional-params="{'type':'provider'}"
                                      :min-matching-chars="0"
                                      :readonly="Boolean(form.id)"
                        ></search-field>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.count"
                                        name="count"
                                        :label-prefix="labelPrefix"
                                        type="number"
                                        validate="required"
                                        :readonly="Boolean(form.id)"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.container_nr"
                                        name="container_nr"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.lager_nr"
                                        name="lager_nr"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.generate_sn"
                                         name="generate_sn"
                                         :label-prefix="labelPrefix"
                                         :readonly="Boolean(form.id)"
                        ></zw-switch-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <zw-select-group name="status"
                                         v-model="form.status"
                                         :label-prefix="labelPrefix"
                                         :options="getSupplyStatuses() | optionsVV"
                                         empty
                                         :empty-disabled="false"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-new-date-group v-model="form.delivery_date"
                                           name="delivery_date"
                                           :label-prefix="labelPrefix"
                                           format="DD.MM.YYYY"
                        ></zw-new-date-group>
                    </b-col>

                    <b-col cols="4">
                        <zw-input-group v-model="form.notice"
                                        name="notice"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-card class="mt-3" :header="$t('stock.group.parts')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="2">{{ $t('stock.label.supply.weight') }}</b-col>
                            <b-col cols="2">{{ $t('stock.label.supply.note') }}</b-col>
                            <b-col cols="2">{{ $t('stock.label.supply.length') }}</b-col>
                            <b-col cols="2">{{ $t('stock.label.supply.width') }}</b-col>
                            <b-col cols="2">{{ $t('stock.label.supply.height') }}</b-col>
                        </b-row>
                        <b-row v-for="(item,index) in form.parts"
                               :key="index"
                        >
                            <b-col cols="2">
                                <zw-input-group v-model="item.weight"
                                                name="weight"
                                                type="number"
                                                disable-label
                                                :readonly="Boolean(form.id)"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="item.note"
                                                name="note"
                                                disable-label
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="item.width"
                                                name="width"
                                                type="number"
                                                disable-label
                                                :readonly="Boolean(form.id)"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="item.height"
                                                name="height"
                                                type="number"
                                                disable-label
                                                :readonly="Boolean(form.id)"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="item.length"
                                                name="length"
                                                type="number"
                                                disable-label
                                                :readonly="Boolean(form.id)"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2" v-if="!Boolean(form.id)">
                                <b-button variant="danger" @click="remove(index)" :title="$t('common.title.delete')">
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row v-if="!Boolean(form.id)">
                            <b-col cols="1">
                                <b-button variant="primary" @click="addItem">
                                    <font-awesome-icon icon="plus"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";

export default {
    name: 'SupplyModal',
    components: {ZwNewDateGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                parts: [{}],
            },
            form: {},
            labelPrefix: 'stock.label.supply.',
            searchUrl: window.apiUrl + '/contact-person/search',
        }
    },
    methods: {
        ...mapGetters('Supply', ['getSupplyStatuses', 'getSupply']),
        shown() {
            const statuses = this.$store.dispatch('Supply/fetchSupplyStatuses')

            let requests = [statuses]
            if (this.payload.id) {
                const supply = this.$store.dispatch('Supply/fetchSupply', this.payload.id)
                requests.push(supply)
            }

            Promise.all(requests)
                .then(() => {
                    if (this.payload.id) {
                        this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getSupply()}))
                    } else {
                        this.form = JSON.parse(JSON.stringify({...this.defaultForm}))
                    }

                    this.form.article_id = this.payload.articleId
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Supply/saveSupply', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        remove(index) {
            this.form.parts = this.form.parts.filter((data, i) => {
                return i != index
            })
        },
        addItem() {
            this.form.parts.push({})
        },
    }
}
</script>